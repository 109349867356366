import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import CustomToast from "../../../../../widgets/toaster";
import AlertModal from "../../../../../widgets/alertModal";
import { deviceRegistration } from "../../../../../service/deviceRegistration";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

interface DateRangeModalProps {
  show: boolean;
  selectedDays: string[];
  fromDate: string;
  toDate: string;
  selectedId: String;
  errors: any;
  modalName: string;
  reason: string;
  setReason: (value: string) => void;
  setErrors: (value: any) => void;
  setToDate: (value: string) => void;
  setFromDate: (value: string) => void;
  setSelectedDays: (value: string[] | number[]) => void;
  handleClose: () => void;

}

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  show,
  selectedDays,
  fromDate,
  toDate,
  selectedId,
  modalName,
  errors,
  reason, 
  setReason,
  setErrors,
  setToDate,
  setFromDate,
  setSelectedDays,
  handleClose,
}) => {

  const { t } = useTranslation();


  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  const maxDate = `${currentYear}-12-31`;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [addHolidayAlert, setAddHolidayAlert] = useState(false);

  //handle error section
  interface Errors {
    reason?: string;
    selectedDays?: string;
    fromDate?: string;
    toDate?: string;
  }

  

  const handleDaysChange = (selectedOptions: any) => {
    setSelectedDays(selectedOptions || []);
  };

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
    setToDate('');
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };

  const handleSubmit = () => {
    const errors: Errors = {};
    if (selectedId !== "" && reason === "") {
      errors.reason = "Please Enter Reason";
    }
    if (!fromDate) {
      errors.fromDate = "Please Select From Date";
    }
    if (!toDate) {
      errors.toDate = "Please Select To Date";
    }
    if (selectedDays.length < 1) {
      errors.selectedDays = "Please Select The Days";
    }
    if (Object.keys(errors).length > 0) {
      console.log(errors, "errors")
      setErrors(errors);
      return
    }
    setErrors({});
    setAddHolidayAlert(true)
  };

  const handleSubmitWeekDays = async () =>{
    const weekDayString = selectedDays.map(d => d).join(',');
    setAddHolidayAlert(false)
    const dataToSend = {  
        id : selectedId,
        week_day : weekDayString,
        from_date : fromDate,
        to_date : toDate,
        reason : "", 
        interface_name : "Add / Update Weekend"
    }
    try {
      const response = await deviceRegistration.AddWeekHoliday(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        handleClose()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handleDayClick = (value: any) => {
    if (selectedDays.includes(value)) {
      console.log( typeof selectedDays, selectedDays, "selectedDays")
      setSelectedDays(selectedDays?.filter(day => day !== value));
    } else {
      setSelectedDays([...selectedDays, value]);
    }
  };

  useEffect(() => {
    if (!show) {
      setSelectedDays([]);
      setFromDate('');
      setToDate('');
    }
  }, [show]);
   
  const daysOptions = [
    { value: '2', label: 'MO' },
    { value: '3', label: 'TU' },
    { value: '4', label: 'WE' },
    { value: '5', label: 'TH' },
    { value: '6', label: 'FR' },
    { value: '7', label: 'SA' },
    { value: '1', label: 'SU' },
  ];

  return (
    <>
     <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 

    <Modal className="top-right-modal-md" centered={false} show={show}>
      <div>
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
          <div onClick={() => handleClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <div>
          <div className="selectWrap" >
            {/* <div className="form-group my-1">
              <label htmlFor="">
              {t('common.selectWeekDays')} <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <Select
                isMulti
                value={selectedDays}
                onChange={handleDaysChange}
                options={daysOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select days"
            />
              {errors.dropDown && <p className="errorText">{errors.dropDown}</p>}
            </div> */}

            <div className="form-group my-1">
              <label>{t('common.from_date')} <span style={{ color: "red" }}>*</span></label>
              <br/>
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="placeholder-style"
                min={new Date().toISOString().split('T')[0]}
                // max={maxDate}
                onKeyDown={(e) => {
                  // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.fromDate && (
                      <p className="errorText">{errors.fromDate}</p>
                    )}
            </div>

            <div className="form-group my-1">
              <label>{t('common.to_date')} <span style={{ color: "red" }}>*</span></label>
              <br />
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="placeholder-style"
                min={fromDate}
                // max={maxDate}
                onKeyDown={(e) => {
                  // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.toDate && (
                      <p className="errorText">{errors.toDate}</p>
                    )}
            </div>

            
            {/* visibility hidden demmy in[ut field] */}
            <div className="form-group my-1 " style={{"visibility": "hidden"}}>
                    <br />
                    <input
                      id="reason"
                      // value={holidayRegister.reason}
                      name="reason"
                      type="text"
                      placeholder={t("userManagement.reason")}
                      className="reasonClass"
                      // onChange={handleInputChange}
                    />
                    {/* {errors.reason && (
                      <p className="errorText">{errors.reason}</p>
                    )} */}
            </div>

          </div>

          <div style={{ display: 'flex', gap: '10px', marginLeft: '35px', marginTop: '15px'}}>
          <label>Select Days<span style={{ color: "red" }}>*</span></label><br></br>
              {daysOptions.map((day: any) => (
                <div
                  key={day.value}
                  onClick={() => handleDayClick(day.value)}
                  style={{
                    padding: '6px',
                    margin: '6px',
                    cursor: 'pointer',
                    backgroundColor: selectedDays.includes(day.value) ? '#007bff' : '#f0f0f0',
                    color: selectedDays.includes(day.value) ? '#fff' : '#000',
                    borderRadius: '46%',
                    userSelect: 'none',
                    fontSize: 'smaller',
                    fontWeight: 'bold',
                    fontFamily: 'Open Sans'
                  }}
                >
                  {day.label}
                </div>
              ))}
            </div>
            {errors.selectedDays && (
              <p className="errorText mx-5 mt-1">{errors.selectedDays}</p>
            )}
           

          <div className="selectWrap">
            {selectedId !== "" && (
            <div className="form-group my-1 ">
                    <label htmlFor="" className="ReasonLabel">
                      {t("userManagement.reason")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <input
                      id="reason"
                      value={reason}
                      name="reason"
                      type="text"
                      placeholder={t("userManagement.reason")}
                      className="reasonClass"
                      onChange={(e) => setReason(e.target.value)}
                    />
                    {errors.reason && (
                      <p className="errorText">{errors.reason}</p>
                    )}
            </div>
            )}

            {/* visibility hidden demmy in[ut field] */}
            <div className="form-group my-1 " style={{"visibility": "hidden"}}>
                    <label htmlFor="" className="ReasonLabel">
                      {t("userManagement.reason")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <input
                      id="reason"
                      // value={holidayRegister.reason}
                      name="reason"
                      type="text"
                      placeholder={t("userManagement.reason")}
                      className="reasonClass"
                      // onChange={handleInputChange}
                    />
                    {/* {errors.reason && (
                      <p className="errorText">{errors.reason}</p>
                    )} */}
            </div>

            {/* visibility hidden demmy in[ut field] */}
            <div className="form-group my-1 " style={{"visibility": "hidden"}}>
                    <label htmlFor="" className="ReasonLabel">
                      {t("userManagement.reason")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <input
                      id="reason"
                      // value={holidayRegister.reason}
                      name="reason"
                      type="text"
                      placeholder={t("userManagement.reason")}
                      className="reasonClass"
                      // onChange={handleInputChange}
                    />
                    {/* {errors.reason && (
                      <p className="errorText">{errors.reason}</p>
                    )} */}
            </div>

          </div>

        </div>
        <div className="bottomArea">
          <button onClick={() => handleClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
          </button>
          <button onClick={() => handleSubmit()} className="squareOrangeBtn3">
            {t('buttons.submit')}
          </button>
        </div>
      </div>
    </Modal>

    <AlertModal 
          show={addHolidayAlert}
          onHide={() => setAddHolidayAlert(false)}
          title='Alert'
          message={modalName}
          onConfirm={handleSubmitWeekDays}
        />
    </>
  );
};

export default DateRangeModal;
