import React, { useEffect, useState } from 'react'
import CustomToast from '../../../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../../../widgets/table'
import { deviceRegistration } from '../../../../../service/deviceRegistration'
import Pagination from '../../../../../widgets/pagination'
import { HolidayNamecolumns } from './columns'
import { userService } from '../../../../../service/userService'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import AlertModal from '../../../../../widgets/alertModal'
import { DEFAULT_PAGE_SIZE } from '../../../../../constants/constants'


interface AddScheduleModalProps {
  setStatusChangeFlag : (time: boolean) => void;
}

const HolidayName : React.FC<AddScheduleModalProps> = ({setStatusChangeFlag}) => {

    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [useEffectRequired, setuseEffectRequired] = useState(false)
    const [permission, setPermission] = useState<any>([]);

    const [deviceRegister, setDeviceRegister] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const currentYear = new Date().getFullYear();
    const minDate = `${currentYear}-01-01`;
    const [keyWord, setkeyWord] = useState("")
    const [showAddModal, setShowAddModal] = useState(false);
    const [modalName, setModalName] = useState(t('applicationConfiguration.addHolidayName'));
    const [holidayRegister, setHolidayRegister] = useState({
      id: "",
      reason:"",
      name:""
    });
    const [statusAlert, setStatusAlert] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState("")
    const [statusId, setStatusId] = useState("")
    interface Errors {
        name?: string,
        reason?: string,
      }
      
      const [errors, setErrors] = useState<Errors>({});
      const [addHolidayAlert, setAddHolidayAlert] = useState(false);
      const [modalEdit, setModalEdit] = useState(false);

    useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
        var storedObject = JSON.parse(storedData);
        getPermissionByRole(storedObject[0].role_master_id);
    }

    listHoliday();
    setuseEffectRequired(false)
    }, [useEffectRequired])
  
      
 //get all_raw_material_type list from api  
 const listHoliday = async () => {
    try {
      const response = await deviceRegistration.listHolidayName(page, pageSize, keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    name: element.name,
                    id : element.id,
                    status: element.status
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

    //get permission by role 
    const getPermissionByRole = async (role : number) => {
        try {
            const response = await userService.getPermissionByRole(role);
            if(response.status && response.statusCode === 200){
            setPermission(response.data.permissions)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const addHolidayClick = () => {
    setModalName(t('applicationConfiguration.addHolidayName'))
    setShowAddModal(true)
    setModalEdit(false)
    }

    const addModalCloseEvent = () => {
        setShowAddModal(false)
        setHolidayRegister({
          name: "",
          id: "",
          reason: ""
          })
        setErrors({})
    }

    const handleSaveNewName = async () => {
    const dataToSend = {   
        name : holidayRegister.name, 
        id: holidayRegister.id, 
        reason : holidayRegister.reason,
        interface_name: "Add Holiday Name",
    }
    try {
        const response = await deviceRegistration.AddHoliday(dataToSend);
        if(response.status && response.statusCode === 200){
          setToastType("success")
          setToastMessage(response.message)
          addModalCloseEvent();
          setShowAddModal(false)
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setAddHolidayAlert(false)
        setShowToast(true)
        setuseEffectRequired(true)
      } catch (error) {
        console.error('user response', error);
      }
  };

  const handleAddAlert = () => {
    const errors: Errors = {};
      if (!holidayRegister.name) {
        errors.name = "Please Enter Holiday Name";
      }
      if (holidayRegister.id && !holidayRegister.reason) {
        errors.reason = "Please Enter Reason";
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setAddHolidayAlert(true)
      setErrors({});
  }

  const handleStatusChange = (policy_id:any , status: string) => {
    setStatusAlert(true)
    setStatusId(policy_id)
      if (status === "active") {
        setUpdatedStatus("inactive")
      } else {
        setUpdatedStatus("active")
      }
  }

  //handle policy status change 
  const handleStatusChangeSubmit = async () => {
      try {
        const response = await deviceRegistration.changeHolidayStatus(statusId, updatedStatus, "Holiday Name Status");
        
        if (response.status && response.statusCode === 200) {
          setToastType("success")
          setToastMessage(response.message)
          setStatusChangeFlag(true)
        }
        else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowToast(true)
        listHoliday();
        setUpdatedStatus("")
        setStatusId("")
        setStatusAlert(false)
      } catch (error) {
        console.error(error);
      }
  }

  const handleEditClick = (id: string) => {
    setModalName(t('applicationConfiguration.editHolidayRegistration'))
    setModalEdit(true)
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    setHolidayRegister({
      name: filteredUserDetails[0]?.name,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason
    })
    setShowAddModal(true)
  }

  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    const errors: Errors = {};
    let regex;

    if (name === "name") {
      regex = /^[a-zA-Z0-9-\s]*$/;
    }

    if (value === "") {
    } else if (regex && !regex.test(value)) {
      return;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }
    setHolidayRegister((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('applicationConfiguration.holidayName')}
                    data={deviceRegister} 
                    columns={HolidayNamecolumns} 
                    isEditable={permission?.includes("edit_holiday")}
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_holiday")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_holiday")}
                    addButtonText={t('applicationConfiguration.addHolidayName')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onAddClick={addHolidayClick}             
                    changeRoleButton={false}
                    onStatusChange={handleStatusChange}
                    onEditClick={handleEditClick}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>


        <Modal 
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addModalCloseEvent()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

              <div>
              <div className= {holidayRegister.id?"selectWrap":"selectWrap-email"}>
                        <div className="form-group my-1">                    
                        <label htmlFor="" >{t('applicationConfiguration.addHolidayName')}<span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                            id="name"
                            value={holidayRegister.name}
                            type='text'
                            name="name"
                            placeholder="Enter New Name"
                            className="placeholder-style"
                            onChange={handleInputChange}
                        />
                        {errors.name && <p className="errorText">{errors.name}</p>}
                        </div>
               
                      {holidayRegister.id && (
                      <div className="form-group my-1 ">
                        <label htmlFor="" className="ReasonLabel">
                          {t("userManagement.reason")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <input
                          id="reason"
                          value={holidayRegister.reason}
                          name="reason"
                          type="text"
                          placeholder={t("userManagement.reason")}
                          className="reasonClass"
                          onChange={handleInputChange}
                        />
                        {errors.reason && (
                          <p className="errorText">{errors.reason}</p>
                        )}
                      </div>
                    )}
                    </div>
              </div>
                <div className="bottomArea">
                  <button onClick={() => addModalCloseEvent()} className="modalCancelBtn">{t('buttons.cancel')}</button>
                  <button onClick={() => handleAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                </div>
            </div>
          
        </Modal>

        <AlertModal 
          show={addHolidayAlert}
          onHide={() => setAddHolidayAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSaveNewName}
        />

        <AlertModal 
          show={statusAlert}
          onHide={() => setStatusAlert(false)}
          title='Alert'
          message={`Change holiday status`}
          onConfirm={handleStatusChangeSubmit}
        />
       
    </div>
  )
}

export default HolidayName
