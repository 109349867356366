import React, {useRef, useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import AlertModal from '../../../../widgets/alertModal';
import { useTranslation } from 'react-i18next';
import {userService as userRoleServive} from '../../../../service/userService';
import { userService as plateRelatedService, userService } from '../../../../service/plateServices';

import { BatchService } from '../../../../service/plateServices/discard';
import { columnsByBatch, columnsByLotBatch, discardColumns } from './columns';
import { visualInspection } from '../../../../service/visualinspection';
import CustomToast from '../../../../widgets/toaster';
import { PlateReconcilationApi } from '../../../../service/plateReconcilation';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants';


interface PlatesProps {
    plateDetails: string[];
    plateBatches: string[];
    handleSort: (key: string, value: boolean) => void;
}

const Plates: React.FC<PlatesProps> = ({
    plateDetails,
    plateBatches,
    handleSort,
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [showAddLotTable, setshowAddLotTable] = useState(false);
  interface Errors {
    remarks?: string;
    reason_id?: string;
    other_reason?: string;
    scan_plate?: string;
    batchCode?:string;
    quantity?:string;
    lot_code?:string;
    activity?:string;
    operator?:string;

  }
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [closeIconText, setCloseIconText] = useState("");
  const [batchPlateList, setBatchPlateList] = useState([]);
  const [batchLotList, setBatchLotList] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [batchList, setBatchList] = useState([]);
  const [useEffectRequiredDiscard, setUseEffectRequiredDiscard] = useState(false)


  const [pageDiscard, setPageDiscard] = useState(1)
  const [pageSizeDiscard, setPageSizeDiscard] = useState(DEFAULT_PAGE_SIZE)
  const [totalPagesDiscard, setTotalPagesDiscard] = useState(0)
  const [totalElementsDiscard, setTotalElementsDiscard] = useState(0)

   // pagination
   const [BatchByPage, setBatchByPage] = useState(1);
   const [BatchByPageSize, setBatchByPageSize] = useState(DEFAULT_PAGE_SIZE);
   const [BatchByTotalPages, setBatchByTotalPages] = useState(0);
   const [BatchByTotalElements, setBatchByTotalElements] = useState(0);

   const [lotByPage, setLotByPage] = useState(1);
   const [lotByPageSize, setLotByPageSize] = useState(DEFAULT_PAGE_SIZE);
   const [lotByTotalPages, setLotByTotalPages] = useState(0);
   const [lotByTotalElements, setLotByTotalElements] = useState(0);

   const [sort, setSort] = useState("id");
   const [order, setOrder] = useState("desc");
   const [permission, setPermission] = useState<any>([]);
   const [lotNum, setLotNum] = useState([]);
   const [addLotData, setaddLotData] = useState({
    batchCode:"",
    plate_quantity: "",
    quantity: "",
    batch_id: "",
    lot_code: ""
    });
    interface NextSerial{
      next_serial_value?: any;
      lot_code?: any;
    }
   const [showToast, setShowToast] = useState(false);
   const [toastMessage, setToastMessage] = useState("");
   const [toastType, setToastType] = useState("");
   const [selectedBatchId, setSelectedBatchId] = useState("");
    const [selectedLotDetails, setSelectedLotDetails] = useState<any>({});
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [inspectionStatus, setInspectionStatus] = useState<any>("failed,passed,pending,Not required");
    const [batchStatus, setBatchStatus] = useState<any | null>("");
    const [batchIds, setBatchIds] = useState<any>([])
    const [showDiscardAlert, setShowDiscardAlert] = useState(false);
    const [actionClearAll, setActionClearAll] = useState(false);
    const [userId, setUserId] = useState<number>(0);






  //api call to get batch list 
  const getBatchList = async (plateStatus: any) => {
    try {
      const response = await BatchService.getBatchList(pageDiscard, pageSizeDiscard, keyWord, "", "0,1,2,3,5", sort, order);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPagesDiscard(response?.totalPages)
            setTotalElementsDiscard(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
            let statusText = plateStatus === 18 ? "Discard" : plateStatus === 1 ? "Exposure" : "Unknown";

            const StatusConstants = {
              QUARANTINE: "0",
              QA_APPROVED: "1",
              READY_TO_USE: "2",
              ACTIVITY_INPROGRESS: "3",
              QA_REJECTED : "4",
              UNDER_TEST:"5",
              BATCH_DISCARD:"6"
            };

            let status;

            // Mapping numeric status to string representation
            switch (element.batch_status) {
                case StatusConstants.UNDER_TEST:
                    status = "Waiting for QA Approval";
                    break;
                case StatusConstants.QA_APPROVED:
                    status = "QA APPROVED";
                    break;
                case StatusConstants.READY_TO_USE:
                    status = "READY TO USE";
                    break;
                case StatusConstants.ACTIVITY_INPROGRESS:
                    status = "ACTIVITY INPROGRESS";
                    break;
                case StatusConstants.QA_REJECTED:
                    status = "QA REJECTED";
                    break;
                case StatusConstants.QUARANTINE:
                    status = "QUARANTINE";
                    break;
                case StatusConstants.BATCH_DISCARD:
                    status = "DISCARD";
                    break;
                default:
                    status = "UNKNOWN STATUS";
            }

             return { 
                 slno: index + 1, 
                 batch_number: element.batch_no,
                 manufacturingDate: element.manufacturing_date,
                 quantity: element.plate_count,
                 batchGrn: element.batch_grn,
                 plateType: element.plate_size_type_name,
                 status: status,
                 id: element.id
             };
           });

           setBatchList(rowData);
          }
      } else {
        setBatchList([])
        setTotalPagesDiscard(0)
        setTotalElementsDiscard(0)
      }
    } catch (error) {
      return error;
    }
  }

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPageDiscard(newPage);
    if(pageSize) {
      setPageSizeDiscard(pageSize)
    }
    setUseEffectRequiredDiscard(true)
  };

    //handle table filters
  const handleFilter = (key: string, value: string) => {
    if(key === "status") {
      setBatchStatus(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setUseEffectRequiredDiscard(true)
  }

  const handleDiscardClick = (slno: string[]) => {
      const filteredPlateDetails = batchList.filter((item : any) => slno.includes(item.slno));
      const selectedbatchIds = filteredPlateDetails.map((item: any) => item.batch_number).join(',');
      if(slno.length > 0 ) {
        setBatchIds(selectedbatchIds)
      } else {
        setBatchIds([])
      }
      setShowDiscardAlert(true)
  }

  //on submit discard click 
  const submitDiscard =async () => {
    setShowDiscardAlert(false)

    const dataToSend = {
        user_id : userId,
        batch_numbers : batchIds,
        interface_name : "Discard Plates"
    }
    try {
      const response = await BatchService.discardPlates(dataToSend);
      if(response.status && response.statusCode === 200){
        // handleClose()
        setToastType("success")
        setActionClearAll(true)
        setToastMessage(response.message)
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequiredDiscard(true)
      // handleClose()
    } catch (error) {
      console.error('user response', error);
    }

  }

   const listLotNumber = async (batchNo: string) => {
    try {
      const response = await visualInspection.listFilterLots(batchNo);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotNum(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //serial number listing for table drop down 
  const listSerialNumber = async (batchNo: string, lotId: string) => {
    try {
      const response = await plateRelatedService.listSerialNumber(batchNo, lotId);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const ListLots = async (BatchByPage: any, BatchByPageSize: any, batchId: string, inspectionStatus: string, lotId?: string) => {
    try {
      const response = await PlateReconcilationApi.ListLots(BatchByPage, BatchByPageSize, batchId, lotId || "", inspectionStatus);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setBatchByTotalPages(response?.totalPages)
            setBatchByTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_no: element.batch_no,
                    batch_id: element.batch_id,
                    lot_code: element.lot_code,
                    plate_count: element.plate_count,
                    inspection_status: element.inspection_status.charAt(0).toUpperCase() + element.inspection_status.slice(1),
                    remaining_plates_for_qr: element.remaining_plates_for_qr,
                    qr_name: element.qr_name
                };
            });
            setBatchPlateList(rowData);
          }
      } else {
        setBatchPlateList([]);
        setBatchByTotalPages(0);
        setBatchByTotalElements(0);
      }
    } catch (error) {
      return error;
    }
  }

  const handleBatchByPageChange = (newPage : any, pageSize?: number) => {
    setBatchByPage(newPage);
    if(pageSize) {
      setBatchByPageSize(pageSize)
      ListLots(newPage, pageSize, addLotData?.batch_id, inspectionStatus)
    } else {
      ListLots(newPage, BatchByPageSize, addLotData?.batch_id, inspectionStatus)
    }
  }

  const handleLotByPageChange = (newPage : any, pageSize?: number) => {
    setLotByPage(newPage);
    if(pageSize) {
      setLotByPageSize(pageSize)
      listBatchPlateDetails(newPage, pageSize , sort ,order, 0, selectedLotDetails?.batch_number, keyWord, selectedLotDetails?.id)
    } else {
      listBatchPlateDetails(newPage, lotByPageSize , sort ,order, 0, selectedLotDetails?.batch_number, keyWord, selectedLotDetails?.id)
    }

  }

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userRoleServive.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleEditClick = (id: any) => {
    setSelectedBatchId(id);
    listLotNumber(id);
    const filteredUserDetails : any = batchList.filter((item: any) => item.batch_number === id);
    ListLots(BatchByPage, BatchByPageSize,filteredUserDetails[0]?.id, inspectionStatus)
    setshowAddLotTable(true)
    // listSerialNumber(filteredUserDetails[0]?.id, filteredUserDetails[0]?.lot_id)
    setaddLotData({
      batchCode: filteredUserDetails[0]?.batch_number,
      batch_id : filteredUserDetails[0]?.id,
      plate_quantity : filteredUserDetails[0]?.remaining_for_lot_assign,
      quantity: "",
      lot_code: ""
    })
  }


//list plate batch detail 
const listBatchPlateDetails = async (BatchByPage: any, BatchByPageSize: any, sort:any , order:any, plateStatus: any, batchId: string, keyWord: any, lot_id: any) => {
  try {
    const response = await BatchService.listLotPlateDetails(BatchByPage, BatchByPageSize, sort, order, plateStatus, batchId, keyWord, lot_id);
    if(response.status && response.statusCode === 200){
        if ( Array.isArray(response.data) && response.data?.length > 0) {
          setLotByTotalPages(response?.totalPages)
          setLotByTotalElements(response?.totalElements)
          let rowData: any = response.data.map((element: any, index: number) => {
            const role = element.role_master_id === "1" ?
               "Admin" : element.role_master_id === "2" ?
               "Operator" : element.role_master_id === "3"? 
               "Lab Operator": null
               const statusNumber = parseInt(element.plate_status, 10); 
               const status = plateRelatedService.getPlateStatus(statusNumber);
              return { 
                  id : element.id,
                  slno: index + 1, 
                  batch_no: element.batch_number,
                  lot_code: element.lot_code,
                  serial_number: element.serial_number,
                  expiry_date: element.expiry_date,
                  manufacturing_date: element.manufacturing_date,
                  qr_name: element.qr_name,
                  assigned_location_name: element.assigned_location_name,
                  assigned_operator_name: element.assigned_operator_name,
                  status: status,
              };
          });

          let serielNum: any = response.data.map((element: any, index: number) => {
              return { 
                value: element.serial_number,
                label: element.serial_number ,
              };
          });
          setBatchLotList(rowData);
        }
    } else {
      setBatchLotList([]);
      setLotByTotalPages(0);
      setLotByTotalElements(0);
    }
  } catch (error) {
    return error;
  }

}

//on cell click 
const handleCellClick = (rowData: any) => {
  if(rowData.column.id === "plate_count"){
    setShowButtonWithCloseIcon(true)
    setSelectedLotDetails(rowData?.row?.original)
    setCloseIconText(rowData?.row?.original?.batch_no)
    listSerialNumber("", rowData?.row?.original?.id)
    listBatchPlateDetails(lotByPage, lotByPageSize , sort ,order, 0, rowData?.row?.original?.batch_number, keyWord, rowData?.row?.original?.id)
  }
}

//on click on selected incubator list 
const handleCloseBtnClick = () => {
  setShowButtonWithCloseIcon(false);
  const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);
  ListLots(BatchByPage, BatchByPageSize, filteredUserDetails[0]?.id, inspectionStatus)
  setBatchLotList([])
  setPlateSerielNums([])
}


const handlePlateDetailsListFilter = (key: string, value: string) => {
  if(key === "search") {
    listBatchPlateDetails(lotByPage, lotByPageSize , sort ,order, 0, selectedLotDetails?.batch_number, value, selectedLotDetails?.id)
  }
}

const handleBatchPlateListFilter = (key: string, value: any) => {
  if (key === "lot_code") {
    const filteredLotDetails:any = batchPlateList.filter((item: any) => item.lot_code === value);
    const filteredBatchDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);  
    ListLots(BatchByPage, BatchByPageSize, filteredBatchDetails[0]?.id, inspectionStatus, filteredLotDetails[0]?.id)
  }
  if (key === "visualInspectionStatus") {
    setInspectionStatus(value)
    ListLots(BatchByPage, BatchByPageSize, addLotData?.batch_id, value)
  }
};


useEffect (() => {
  var storedData = localStorage.getItem('userDetails');

  if(storedData) {
    var storedObject = JSON.parse(storedData);
    getPermissionByRole(storedObject[0].role_master_id);
    setUserId(storedObject[0].id);
  }

  getBatchList("1")
  setUseEffectRequiredDiscard(false)
}, [useEffectRequiredDiscard])


  const onCloseButtonClick = () =>{
    setBatchPlateList([])
    setshowAddLotTable(false)
    setaddLotData({ 
      batchCode:"",
      plate_quantity: "",
      quantity: "",
      batch_id: "",
      lot_code: ""
    })
  }

  return (
    <div className="me-3" style={{ marginTop: "10px" }}>
    <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
    {!showAddLotTable && (
        <>
        <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
        <CustomTable
              tableHead={t('plateInventory.batches')}
              data={batchList}
              columns={discardColumns}
              isEditable={false}
              totalElements={totalElementsDiscard}
              isActionVisible={true}
              isViewVisible={false}
              addButton={false}
              addPlateUploadBtn={false}
              showBlueBtn={false}
              plateBatch={plateBatches}
              isSeachable={false}
              showPlateStatus={true}
              onFilterClick={handleFilter}
              showaddLotButton={true}
              onSortClick={handleSort}
              onEditClick={handleEditClick}
              showFromDateFilter={false}
              showToDateFilter={false}
              actionCheckOrangeButton={true}
              actionCheckOrangeBtnText={t('plateInventory.discard')}
              onActionCheckOrangeButtonClick={handleDiscardClick}
              clearAll={actionClearAll}
            />
          </div>
       
            <div> 
                <Pagination
                page={pageDiscard}
                totalPages={totalPagesDiscard}
                handlePageChange={handlePageChange}
              />
            </div>
        </>
        )}

        {showAddLotTable && (
          !showButtonWithCloseIcon ? (
          <div>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              tableHead="Plates Lot"
              data={batchPlateList}
              columns={columnsByBatch}
              isEditable={false}
              totalElements={BatchByTotalElements}
              isActionVisible={false}
              isViewVisible={false}
              addButton={false}
              lotCodeNumber={lotNum}
              isSeachable={false}
              showPlateStatus={false}
              onSortClick={handleSort}
              onCellClick={handleCellClick}
              buttonWithCloseIcon={true}
              buttonWithCloseIconText={"Cancel"}
              onCloseBtnClick={onCloseButtonClick}
              onFilterClick={handleBatchPlateListFilter}
              isQrDownloadable={false}
              actionCheckOrangeButton={false}
            />
          </div>
            <div>
            <Pagination
                  page={BatchByPage}
                  totalPages={BatchByTotalPages}
                  handlePageChange={handleBatchByPageChange}
                />
            </div>
          </div>
          ) : (
            <div>
            <div className='mx-3 pe-3' style={{"height":"45vh"}}>
              <CustomTable
                tableHead='Plate Details' 
                data={batchLotList} 
                columns={columnsByLotBatch} 
                isEditable={false} 
                isActionVisible={false} 
                isViewVisible={false} 
                totalElements={lotByTotalElements}
                isSeachable={true}
                isToggleStatus={false}  
                plateSerialNumber={plateSerielNum}    
                showSerielNoFilter={true}
                onFilterClick={handlePlateDetailsListFilter}
                buttonWithCloseIcon={showButtonWithCloseIcon}
                buttonWithCloseIconText={closeIconText}
                onCloseBtnClick={() => handleCloseBtnClick()}
            />
            </div>
            <div>
              <Pagination
                page={lotByPage}
                totalPages={lotByTotalPages}
                handlePageChange={handleLotByPageChange}
              />
            </div>
            </div>
          )
        )}
         
          {/* Alert modal for submit discard */}
          <AlertModal
          show={showDiscardAlert}
          onHide={() => setShowDiscardAlert(false)}
          title='Alert'
          message={`Discard`}
          onConfirm={submitDiscard}
        /> 

    </div>
  )
}

export default Plates